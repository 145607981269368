/* poppins-regular - latin-ext_latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('./fonts/poppins-v20-latin-ext_latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./fonts/poppins-v20-latin-ext_latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('./fonts/poppins-v20-latin-ext_latin-regular.ttf') format('truetype'); /* Safari, Android, iOS */
}
/* poppins-500 - latin-ext_latin */
@font-face {
  font-family: 'Poppins Medium';
  font-style: normal;
  font-weight: 500;
  src: local(''), url('./fonts/poppins-v20-latin-ext_latin-500.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./fonts/poppins-v20-latin-ext_latin-500.woff') format('woff'),
    /* Modern Browsers */ url('./fonts/poppins-v20-latin-ext_latin-500.ttf') format('truetype'); /* Safari, Android, iOS */
}
/* poppins-600 - latin-ext_latin */
@font-face {
  font-family: 'Poppins SemiBold';
  font-style: normal;
  font-weight: 600;
  src: local(''), url('./fonts/poppins-v20-latin-ext_latin-600.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./fonts/poppins-v20-latin-ext_latin-600.woff') format('woff'),
    /* Modern Browsers */ url('./fonts/poppins-v20-latin-ext_latin-600.ttf') format('truetype'); /* Safari, Android, iOS */
}

body,
html {
  font-family: 'Poppins', sans-serif;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 10px;
  overflow: hidden;
  min-width: 320px;
}

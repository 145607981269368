/* You can add global styles to this file, and also import other style files */
@import '@angular/cdk/overlay-prebuilt.css';

@import '../../shared-assets/style';
@import '../../shared-assets/fonts';
@import '../../shared-assets/forms';
@import '../../shared-assets/buttons';
@import '../../shared-assets/responsive';
@import '../../shared-assets/table';
@import '../../shared-assets/inputs';

@tailwind base;
@tailwind utilities;

a {
  text-decoration: none;
}

p {
  font-family: 'Poppins', sans-serif;
}

b {
  font-family: 'Poppins Medium', sans-serif;
}

textarea {
  font-family: 'Poppins', sans-serif;
}

h6 {
  font-family: 'Poppins Medium', sans-serif;
  font-size: 1.1rem;
}

.text-button {
  background-color: transparent;
  border: 0 none transparent;
  cursor: pointer;
  outline: none;
  padding: 0;
  margin: 0;
  font-size: inherit;
}

// Define a style reset class for inputs
input.reset {
  padding: 0;
  background-color: transparent;
  color: black;
  border: none;
  outline: none;

  // MM5-388 - safari, and possible in the future other browsers, will apply a custom styling over type="search"
  appearance: initial;

  &:focus {
    outline: none;
    outline-offset: 0;
  }
}

.clear-fix {
  clear: both;
}

.hidden {
  display: none;
}

//font style
%sub-header {
  font-size: 1.5rem;
  font-weight: bold;
}

h2.section-title {
  border-bottom: 1px solid lightgray;
  padding-bottom: 5px;
}

.error {
  margin-top: 0.5rem; //.25rem
  color: $color-error;
  font-size: 1.2rem;
}

.success {
  margin-top: 0.25rem;
  color: $color-success;
}

.btn {
  display: block;
  width: 100%;
  margin-top: 1rem;
  border-radius: 2px;
  border: none;
  background-image: none;
  background-color: transparent;
  @extend %font-weight-normal;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  outline: 0;
  position: relative;
}

.rotate-icon-more-vertical-90deg {
  transform: rotate(90deg);
  padding: 0 5px;
}

.no-mouse-events {
  pointer-events: none;
}

@keyframes global_fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes global_highlight {
  0% {
    background: rgba(0, 0, 0, 0);
  }

  25% {
    background: rgba(0, 0, 0, 0.14);
  }

  50% {
    background: rgba(0, 0, 0, 0.05);
  }

  75% {
    background: rgba(0, 0, 0, 0.14);
  }

  100% {
    background: none;
  }
}

.cdk-overlay-dark-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  animation: global_fadeIn 70ms;
  transition-timing-function: cubic-bezier(0.2, 0.5, 0.3, 1);
}
.notification-panel {
  transition: margin-bottom 250ms ease-in-out;
}

.long-and-truncated {
  flex: 0 1 auto;

  @extend %font-ellipsis;
}

// These are only here to give us intellisense when going element classes
.primary-background {
}
.primary-background-on-active {
}
.primary-text {
}
.primary-text-accent {
}
.primary-text-hover {
}
.primary-text-active {
}
.primary-text-and-background {
}
.primary-text-color-border {
}
.primary-border {
}
.primary-border-on-active {
}
.primary-border-on-hover {
}
.primary-border-on-focus {
}
.primary-border-bottom-on-focus {
}
.primary-text-on-active {
}
.primary-text-accent-on-active {
}
.primary-hover {
}
.primary-active {
}
.primary-stroke-background {
}

body.dragging {
  pointer-events: none;
}

body.ie .cdk-overlay-dark-backdrop {
  animation: none !important;
}

input::-ms-clear {
  display: none;
}

body.drag-drop-dragging {
  cursor: grabbing !important;
}

// media query breakpoints
// Based on the same as ngflex layout, for consistency
// https://github.com/angular/flex-layout/wiki/Responsive-API
$mq-breakpoint-xs: 320px; // Office Online Connector
$mq-breakpoint-sm: 600px;
$mq-breakpoint-md: 960px;
$mq-breakpoint-lg: 1260px;
$mq-breakpoint-xl: 1920px;
$mq-breakpoint-xxl: 5000px;

/** Target a specific area **/
@mixin xxs {
  @media (max-width: $mq-breakpoint-xs) {
    @content;
  }
}

@mixin xs {
  @media (max-width: $mq-breakpoint-sm - 1) {
    @content;
  }
}

@mixin sm {
  @media (min-width: $mq-breakpoint-sm) and (max-width: $mq-breakpoint-md - 1) {
    @content;
  }
}

@mixin md {
  @media (min-width: $mq-breakpoint-md) and (max-width: $mq-breakpoint-lg - 1) {
    @content;
  }
}

@mixin lg {
  @media (min-width: $mq-breakpoint-lg) and (max-width: $mq-breakpoint-xl - 1) {
    @content;
  }
}

@mixin xl {
  @media (min-width: $mq-breakpoint-xl) and (max-width: $mq-breakpoint-xxl - 1) {
    @content;
  }
}

/** Target lower-than **/
@mixin lt-sm {
  @media (max-width: $mq-breakpoint-sm - 1) {
    @content;
  }
}

@mixin lt-md {
  @media (max-width: $mq-breakpoint-md - 1) {
    @content;
  }
}

@mixin lt-lg {
  @media (max-width: $mq-breakpoint-lg - 1) {
    @content;
  }
}

@mixin lt-xl {
  @media (max-width: $mq-breakpoint-xl - 1) {
    @content;
  }
}

/** Target greater-than **/
@mixin gt-xs {
  @media (min-width: $mq-breakpoint-sm) {
    @content;
  }
}

@mixin gt-sm {
  @media (min-width: $mq-breakpoint-md) {
    @content;
  }
}

@mixin gt-md {
  @media (min-width: $mq-breakpoint-lg) {
    @content;
  }
}

@mixin gt-lg {
  @media (min-width: $mq-breakpoint-xl) {
    @content;
  }
}

/** Helpful classes **/
@include lt-sm {
  .fxHide-xs,
  .fxShow-gt-xs,
  .fxHide-lt-sm {
    display: none !important;
  }

  .fxShow-xs {
    display: initial;
  }
}

@include gt-xs {
  .fxShow-xs {
    display: none !important;
  }

  .fxShow-gt-xs {
    display: initial;
  }
}

@include lt-md {
  .fxHide-lt-md {
    display: none !important;
  }
  .fxShow-gt-sm {
    display: none !important;
  }
}

@include gt-sm {
  .fxHide-gt-sm {
    display: none !important;
  }
  .fxShow-gt-sm {
    display: initial;
  }
}

$color-primary: #31b69f;
$color-secondary: #aaaaaa;

$background-color-primary-card-simple: rgba(0, 0, 0, 0.4);
$background-color-primary: #ffffff;
$color-error: #d33a17;
$color-danger: #d33a17;
$color-error-hover: #d64030;
$color-danger-hover: #d64030;
$color-warning: rgb(255, 166, 0);
$color-success: #028840;

$color-sub-header: #646464;
$color-primary-icon: #212121;
$color-primary-icon-hover: darken($color-primary-icon, 7%);
$color-primary-icon-active: darken($color-primary-icon, 7%);
$color-primary-headliner: #171717;
$color-primary-btn-bg: transparent;

$color-instances-detail-view-workflow-instance-data-light-sub-text: #666666;

$color-background-overlay: rgba(0, 0, 0, 0.5);

$main-btn-bg: rgba(0, 0, 0, 0.05);
$main-btn-hover: #e4e4e4;
$light-btn-hover: #e8e8e8;
$main-btn-active: rgba(0, 0, 0, 0.07);
$main-box-shadow: rgba(0, 0, 0, 0.1);

$secondary-btn-hover: rgba(0, 0, 0, 0.07);
$secondary-btn-active: rgba(0, 0, 0, 0.17);

$light-alt-background-color: #f0f0f0;
$light-alt-background-hover: #e4e4e4;

//texts
$color-text: #666666;
$color-text-light: #ffffff;
$color-text-light-gray: #949494;
$color-text-dark: #212121;
$color-text-darker: #171717;
$color-text-disabled: #c6c6c6;
$color-text-light-disabled: #ffffff80;
$color-text-dark-disabled: #21212180;
$color-text-inverted: #ffffff;

$color-text-link: #666666;
$color-text-link-active: $color-text-dark;
$color-text-link-hover: $color-text-dark;

//item sizes
$item-xxs: 2rem;
$item-xs: 2.4rem;
$item-small: 3.2rem;
$item-large: 4rem;

// for hit area on touch devices
$touch-area-target-size: 4.4rem;

//backgrounds
$background-primary-color: #212121;
$background-danger-color: #d33a17;

$background-light-color: rgba(0, 0, 0, 10%);
$background-light-color-hover: rgba(0, 0, 0, 33%);
$background-lighter-color-hover: lighten($background-light-color, 10%);
$background-light-color-active: rgba(0, 0, 0, 10%);
$background-dark-color-hover: lighten($background-primary-color, 20%);

$background-gray-color: #fafafa;
$background-light-gray-color: #ffffff80;
$background-lighter-gray-color: rgba(0, 0, 0, 33%);

$background-light-transparent-color: #0000001a;
$background-light-transparent-color-hover: #0000004d;
$background-lighter-transparent-color-hover: #0000000d;
$background-dark-transparent-color: #ffffff33;
$background-darker-transparent-color: #000000cc;

$background-inverted-color: rgba(0, 0, 0, 0.7);
$background-light-inverted-color: rgba(0, 0, 0, 0.25);

$color-light-background-color: #ffffff;
$color-primary-background-color: #f0f0f0;
$color-primary-background-color-hover: darken($background-color-primary, 7%);
$color-secondary-icon: #171717;
$color-secondary-background-color: #f7f7f7;
$color-tertiary-background-color: #f8f8f8;
$color-clear-search-btn-background: #f1f1f1;

//borders and outline
$border-color: rgba(0, 0, 0, 0.2);
$border-light-color: #ffffff;
$border-primary-color: #212121;
$border-gray-color: #949494;
$border-lighter-color: rgba(0, 0, 0, 33%);

$color-border: #ebebeb;
$color-border-alt: #e4e4e4;
$color-border-secondary: #e0e0e0;
$color-border-card-simple: rgba(255, 255, 255, 0.8);

$outline-dashed-color: #212121;
$outline-dashed-light-color: #ffffff;

//shadow
$shadow-color: #949494;
$shadow-light-color: #ffffff;
$shadow-primary-color: #212121;
$shadow-profile-color: #ffffff80;
$shadow-gray-color: #707070;

//breadcrumbs
$breadcrumb-divider-border: rgba(0, 0, 0, 0.2);
$breadcrumbs-container-border: rgba(0, 0, 0, 0.2);

// tabs
$tabs-border-color: rgba(0, 0, 0, 0.2);

//popup - write-issue-dialog
$color-write-issue-dialog-footer-cancel-btn-text: #aaaaaa;

//feedback
$feedback-btn-shadow-hover: rgba(0, 0, 0, 0.17);

$color-feedback-attach-file-container-color: #aaaaaa;
$color-feedback-file-item-remove-btn-bg: rgba(102, 102, 102, 0.68);
$color-feedback-file-item-remove-btn-bg-hover: rgba(102, 102, 102, 0.8);
$color-feedback-file-item-remove-btn-bg-active: rgba(102, 102, 102, 0.9);
$color-feedback-file-item-remove-btn-shadow: rgba(0, 0, 0, 0.05);
$color-feedback-file-list-border: #aaaaaa;

//** hero-banner
$color-hero-banner-primary-text: #aaaaaa;
$color-hero-banner-secondary-text: #666666;
$color-hero-banner-box-shadow: rgb(0 0 0 / 19%);
$color-hero-banner-icon: #ffffff;
$color-hero-banner-icon-button-bg: #444;
$color-hero-banner-icon-button-bg-hover: lighten($color-hero-banner-icon-button-bg, 40%);
$color-hero-banner-icon-button-bg-active: lighten($color-hero-banner-icon-button-bg, 50%);

//** folder-list
$color-folder-item-hover: #949494;
$color-folder-more-menu-horizontal-hover: rgba(0, 0, 0, 0.05);
$color-folder-more-menu-horizontal-active: rgba(0, 0, 0, 0.1);
$color-folder-list-selected-tree-node-bg: #f7f7f7;

//** collection-overview-list
$color-collection-item-shadow: #f3f5f6;
$color-collection-item-shadow-hover: darken(#f3f5f6, 3%);
$color-folder-more-menu-horizontal-hover: rgba(0, 0, 0, 0.05);
$color-folder-more-menu-horizontal-active: rgba(0, 0, 0, 0.1);

//** collection-list
$collection-list-user-icon: #171717;

$share-overview-icon-link: #3490de;
$share-overview-icon-mail: #2ed573;
$share-overview-icon-user: #ffa502;
$share-overview-icon-group: #ff4757;

//** status list
$status-list-options-btn-icon-active: darken($color-primary-icon, 30%);

//left menu:hover
$color-left-menu-folder-list-title-hover: darken($color-primary-headliner, 6.4%); //darken #646464 6.4% to get #545454

//hover for menu
$color-left-menu-folder-list-bg-hover: darken($background-color-primary, 3%); //darken #ffffff 3% to get #f7f7f7

//Topbar

//** freetext search
$color-freetext-search-header-count: #aaaaaa;

//** asset-details
$color-search-asset-details-text: #aaaaaa;

//** asset-list
$color-freetext-search-thumbnail-wrapper-focused: #f7f7f7;

// asset-list
$color-asset-list-options-menu-bg-hover: #dddddd;
$color-asset-list-options-menu-bg-active: darken(#dddddd, 5%);
$color-option-menu-selector-bg-required: #eeeeee;

// option menu selector
$color-option-menu-selector-bg-hover: #dddddd;
$color-option-menu-selector-bg-active: darken(#dddddd, 5%);

$menu-close-background-color: #f0f0f0;

//profile
$color-profile-image-overlay-bg: rgba(0, 0, 0, 0.25);
$color-profile-reset-btn-filled: #dbdbdb;

//breadcrumb bar
$color-breadcrumb-link-segment-border-hover: darken($color-text, 3%);
$color-breadcrumb-link-segment-border-active: darken($color-text, 6%);
$color-breadcrumb-link-segment-active: darken($color-text, 6%);

// asset thumb
$color-asset-thumb-bg: #d1d1d1;

//filter-menu
$color-filter-menu-edit-color: #666666;

//**asset-list-filter-btn
$color-filter-btn-bg-hover: #dddddd;
$color-filter-btn-bg-active: darken(#dddddd, 5%);

//asset box-view
//** asset item preview
$color-box-view-item-asset-status-active-background: #cdcdcd;
$color-asset-preview-annotation-select-layer-bg: rgba(0, 0, 0, 0.5);

//popup - thumbnail-selector
$color-thumbnail-selector-cancel-btn-bg: #f7f7f7;

//** asset preview container
$color-box-view-img-preview-1color-granit: rgba(255, 255, 255, 1);
$color-box-view-img-preview-2color-granit: rgba(242, 242, 242, 1);

//** Asset-info-item
$color-asset-info-value-text: #666666;

//asset list-view
$list-view-tbody-tr-hover: darken($color-primary-background-color, 4%);
$list-view-tbody-tr-active: darken($color-primary-background-color, 6%);

// asset selector
$color-asset-selector-asset-sort-selector-bg-hover: #dddddd;
$color-asset-selector-asset-sort-selector-bg-active: darken(#dddddd, 5%);

// asset comparator
$color-assets-comparator-header-title-count: #666666;
$color-asset-comparator-mode-selector-bg-hover: #dddddd;
$color-asset-comparator-mode-selector-bg-active: darken(#dddddd, 5%);

// duplicate asset selector
$color-asset-duplicate-selector-asset-count-selector-bg-hover: #dddddd;
$color-asset-duplicate-selector-asset-count-selector-bg-active: darken(#dddddd, 5%);
$color-selected-assets-overlay-more-btn-icon-active: darken(#666666, 10%);

// pagin
$color-link-page-number-hover: darken($color-text, 20%);
$color-paging-text-color: #aaaaaa;

//asset list

// ** asset list folder
$color-folder-menu-folder-count: #aaaaaa;

// ** asset list folder
$color-collection-overview-menu-folder-count: #aaaaaa;

// ** asset list headline section
$color-asset-list-headline-color: #aaaaaa;
$color-asset-list-options-btn-bg-hover: #dddddd;
$color-asset-list-options-btn-bg-active: darken(#dddddd, 5%);

// ** asset list - folders
$color-asset-list-bg: darken($background-color-primary, 3%); //darken #ffffff 3% to get #f7f7f7;
$color-asset-list-bg-even-num: darken($background-color-primary, 5%); //darken #ffffff 3% to get #f7f7f7;

//** asset list
$color-asset-list-btn-bg-hover: #dddddd;
$color-asset-list-btn-active-bg: darken(#dddddd, 5%);

//** list-view-column-selector

// collection popup
$color-collection-rename-dialog-close-btn-text: #aaaaaa;

//share-popup
$color-share-dialog-close-share-btn-text: #aaaaaa;

//Asset preview

//** preview - title-bar
$color-preview-container-digi-title-bar-bg: #f7f7f7;
$color-preview-container-section-title-wrapper-count-text: #aaaaaa;

//** preview - editor
$tool-button-background-color-active: rgba(0, 0, 0, 0.1);

//focused-asset
$color-focused-asset-asset-navigation-current-text: #171717;
$color-focused-asset-asset-navigation-link-text-hover: #171717;

$crop-preset-info-color: #666666;

$asset-zoom-btn-bg: #f7f7f7;
$asset-zoom-btn-bg-hover: #ededed;
$asset-zoom-btn-bg-active: rgba(0, 0, 0, 0.1);

//** model header
$crop-header-close-btn-color: #a7a7a7;
$crop-header-close-btn-bg-active: darken($background-color-primary, 7%);
$crop-model-title-label: #171717;

//asset-edit-dialog
$info-title-wrapper-text: #171717;
$info-title-text-is-read: #171717;
$color-notification-message-bg: #e0e0e0;

//trimmer
$color-trimmer-drag-bar-border-bg: rgba(0, 0, 0, 0.3);
$color-trimmer-selector-border-shadow: rgba(0, 0, 0, 0.16);
$color-trimmer-selector-wrapper-unselected-hide-bg: rgba(0, 0, 0, 0.25);

//** notification-title
$notification-title-color: #212121;
$notification-unread-mark: #0071eb;

//**simple-notification
$info-title-wrapper-bg-hover: #f7f7f7;
$info-title-wrapper-bg-active: darken(#f7f7f7, 3%);

//** group badge
$color-member-group-badge-icon: rgba(0, 0, 0, 0.1);

//** asset-related-list
$color-asset-related-list-related-filter-placeholder-text: #aaaaaa;

//** comments
$color-comments-empty-title: #949494;
$asset-comments-comment-bg: #f7f7f7;
$asset-comments-comment-date: #666666;
$asset-comments-comment-close-btn-text: #949494;

//** add-comment
$color-add-comment-comment-placeholder-text: #949494;
$color-add-comment-comment-btn-icon-hover: #171717;
$color-add-comment-add-annotation-wrapper-btn-text: #949494;
$color-add-comment-add-button-wrapper-cancel-btn-bg: #f7f7f7;

//** inline-metadata-editor-form
$color-inline-metadata-editor-group-head-bg: #f2f2f2;
$color-footer-btn-cancel-bg: #f7f7f7;
$color-footer-btn-cancel-bg-hover: rgba(0, 0, 0, 0.14);
$color-footer-btn-cancel-bg-active: rgba(0, 0, 0, 0.17);
$color-footer-btn-save-bg-hover: rgb(45, 164, 143);
$color-footer-btn-save-bg-active: rgb(43, 157, 138);

//inputs
//** tree-input
$color-tree-selector-path-text: #666666;

//** money-input
$color-money-input-label: #171717;

//** edit-multi-combo
$color-edit-multi-combo-value-input-label: #171717;
$color-edit-multi-combo-element-remove-btn-hover: rgba(0, 0, 0, 0.14);
$color-edit-multi-combo-element-remove-btn-active: rgba(0, 0, 0, 0.17);

//** edit-multi-combo-popup
$color-edit-multi-combo-popup-btn-hover-bg: #f7f7f7;
$color-edit-multi-combo-popup-btn-active-bg: rgba(0, 0, 0, 0.17);

//** multi-combo
$color-multi-combo-element-remove-btn-hover: rgba(0, 0, 0, 0.14);
$color-multi-combo-element-remove-btn-active: rgba(0, 0, 0, 0.17);

// metadata-editor-input
$color-metadata-editor-input-title: #171717;
$color-metadata-editor-input-expand-button-bg-hover-bg: #f1f1f1;
$color-metadata-editor-input-edit-btn-color-active-bg: rgba(0, 0, 0, 0.08);
$color-metadata-editor-input-expanded-bg: #f1f1f1;
$metadata-editor-input-digi-square-btn-color: #666666;
$color-metadata-editor-input-availability-visible-overlay-bg: rgba(#9f9f9f, 0.5);

//date-input
$color-date-input-clear-hover: rgba(0, 0, 0, 0.05);
$color-date-input-flatpickr-months-next-prev-month-icon-hover: #171717;
$color-datepicker-day-hover: #777777;
$color-datepicker-day-active: #888888;
$color-datepicker-day-selected: #666666;
$color-datepicker-today-hover: #e6e6e6;
$color-datepicker-text: #393939;

//** Image Cropper
$background-cropper-overlay: rgba(0, 0, 0, 0.5);

//Multi-metadata-editor
$color-mme-asset-selector-header-sort-btn-text: #171717;
$color-mme-asset-selector-header-sort-popup-title-active: #171717;
$color-mme-asset-selector-asset-list-header-count: #949494;
$color-mme-asset-selector-asset-list-header-title: #171717;

//**multi-metadata-editor tree-selector
$color-mme-tree-selector-header-title-text: #171717;
$color-mme-tree-selector-header-close-btn-text: #aaaaaa;

//** multi-metadata error-popup

//uploader overlay
$color-upload-job-step-bold: #000000;
$color-upload-topbar-border: #cbcbcb;
$color-upload-status-icon-header: rgba(0, 0, 0, 0.4);
$color-upload-asset-list-list-remove-btn-text: #aaaaaa;
$color-upload-asset-list-list-remove-btn-border-bottom-hover: #aaaaaa;
$color-upload-asset-list-list-remove-btn-border-bottom-active: darken($color-upload-asset-list-list-remove-btn-border-bottom-hover, 3%);
$color-upload-status-footer-btn-bg-active: darken($color-primary-icon, 3%);

// drag and drop
$color-multiselect-btn-border: #e4e4e4;

//drag-and-drop full-screen
$color-drag-and-drop-full-screen-bg: rgba(255, 255, 255, 0.95);

//list-popup-item
$color-list-popup-item-bg-hover: #f0f0f0;
$color-list-popup-item-btn-bg-hover: #f0f0f0;
$color-list-popup-item-btn-bg-active: rgba(0, 0, 0, 0.17);

//workflow-status-badge
$color-asset-status-badge-bg: #aaaaaa;

//workflow-popup
$color-asset-status-popup-cancel-btn-bg: #f7f7f7;

//duplicate assets
$color-duplicate-asset-modal-cancel-btn-bg: #f7f7f7;

//page info
$color-pdf-page-info-bg: rgba(0, 0, 0, 0.288);

//Login-button
$login-btn-user-avatar-btn-bg-hover: rgba(0, 0, 0, 0.1);
$login-btn-user-avatar-btn-bg-active: rgba(0, 0, 0, 0.17);
$login-btn-img-overlay-bg: #000000;

//Login-dialog
$login-dialog-header-line-color: #171717;
$login-dialog-form-label-color: #171717;

//forgot-password
$forgot-password-icon: #aaaaaa;

//settings

//settings - asset-picker
$color-asset-picker-header-text: #aaaaaa;
$color-asset-picker-close-btn-icon: #aaaaaa;

//reset-password
$reset-password-strong-text-color: #171717;

//checkmark
$color-list-favorite-hover-border: #b3b2b2;
$color-list-favorite-border: #cccccc;

//checkmark
$color-checkmark-border: #cccccc;

//step
$color-step-border: #cccccc;

//toggle-switch
$toggle-switch-background-color: #949494;
$toggle-switch-background-color-checked: #212121;
$toggle-switch-background-color-hover: #666666;

//dark-mode
$dark-mode-color: #cccccc;
$dark-mode-hover-color: #ffffff;
$dark-mode-border-color: #525252;
$dark-mode-button-background-color: #444444;
$dark-mode-button-background-color2: #525252;
$dark-mode-zoom-button-color: #525252;
$dark-mode-zoom-button-hover-color: #595959;

//**compareassets
$compare-metadata-asset-list-add-asset-bg: #e4e4e4;
$compare-metadata-asset-list-page-bg: #e0e0e0;
$compare-metadata-asset-list-page-hover-bg: darken(#e0e0e0, 15%);
$compare-metadata-group-header-bg: #f7f7f7;
$compare-metadata-metadata-value-odd-bg: whitesmoke;
$compare-metadata-metadata-value-odd-hover-bg: darken($compare-metadata-metadata-value-odd-bg, 3%);
$compare-metadata-metadata-value-even-hover-bg: darken($color-primary-background-color, 3%);

$compare-metadata-asset-list-scrollbar-bg: #e4e4e4;

//**facial detection
$facial-recognition-loading-bg: rgba(255, 255, 255, 0.5);
$facial-recognition-face-hover-border-color: darken($color-border, 10%);

//** business workflow editor
$business-flow-editor-transition-color: #d3d3d3;

//Creative Cloud Connector

//Link Manager LinkStatus
$link-manager-green-state: #00bf7d;
$link-manager-orange-state: #ff5e1a;
$link-manager-red-state: #d33a17;

%font-size-folder-title {
  font-size: 2rem;
}
%font-size-modal-title {
  font-size: 2rem;
}

%font-size-xlarge {
  font-size: 1.8rem;
}
%font-size-big {
  font-size: 1.6rem;
}
%font-size-regular {
  font-size: 1.4rem;
}

%font-size-regular-oc {
  font-size: 14px; // Use pixels for OC to avoid issue with sporadic resizing.
}
%font-size-mid-small {
  font-size: 1.3rem;
}

%font-size-small {
  font-size: 1.2rem;
}

%font-size-smaller {
  font-size: 1.1rem;
}

%font-size-icon-xs {
  font-size: 1.4rem;
}

%font-size-icon-small {
  font-size: 1.6rem;
}

%font-size-icon {
  font-size: 1.8rem;
}

%font-size-icon-large {
  font-size: 2rem;
}

%font-size-icon-xlarge {
  font-size: 2.2rem;
}

%font-size-icon-xxlarge {
  font-size: 2.4rem;
}

%font-size-extra-small {
  font-size: 0.8rem;
}

%big-font {
  font-family: 'Poppins Medium', sans-serif;
}

%regular-font {
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
}

%bold-font {
  font-family: 'Poppins Medium', sans-serif;
}

%extra-bold-font {
  font-family: 'Poppins SemiBold', sans-serif;
}

%font-weight-bold {
  font-weight: 900;
}

%font-weight-strong {
  font-weight: 600;
}

%font-weight-normal {
  font-weight: 400;
}

%font-weight-light {
  font-weight: 100;
}

%font-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  // Hide default tooltip on Safari
  &::after {
    content: '';
    display: block;
  }
}

%font-ellipsis-reverse {
  @extend %font-ellipsis;
  direction: rtl;
  unicode-bidi: embed;
  text-align: left;
}

%font-overflow-wrap {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

%font-overflow-wrap-hyphens {
  @extend %font-overflow-wrap;
  hyphens: auto;
}

%border-radius-regular-deprecated {
  border-radius: 3px;
}

%border-radius-regular {
  border-radius: 5px;
}

%border-radius-large {
  border-radius: 8px;
}

%border-radius-round-small {
  border-radius: 3.2rem;
}

%border-radius-round {
  border-radius: 4rem;
}

%border-radius-circle {
  border-radius: 50%;
}

%small-box-shadow {
  box-shadow: 0 0 8px $main-box-shadow;
}

%regular-box-shadow {
  box-shadow: 0 1px 15px $main-box-shadow;
}

%font-primary-header-title {
  @extend %font-size-big;
  @extend %font-weight-bold;
}

%font-sub-title-normal {
  @extend %font-size-regular;
  @extend %font-weight-normal;
}

%page-headline-title {
  @extend %font-size-big;
  @extend %big-font;
  color: $color-text-dark;
}

%page-headline-title-count {
  @extend %font-size-big;
  @extend %big-font;
  color: $color-text-dark;
  padding-left: 6px;
}

%center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

%cdk-keyboard-focused-btn {
  outline: $outline-dashed-color dashed 2px;
  outline-offset: -2px;
  transition: none;
}

$easing-explode: cubic-bezier(0.42, 0, 0.53, 3);

$theme: (
  InputBackgroundColorLight: #ffffff,
  InputBackgroundColorDark: #fafafa,
  InputTextColor: #666666,
  InputBorderColor: #ededed,
  InputBorderWidth: 1px,
  InputBorderStyle: solid,
  InputBorderRadius: 3px,
);

$bezierTimingFunction: cubic-bezier(0.175, 0.885, 0.32, 1.275);

// header height
$topBarRowHeight: 5.6rem;
$topBarHeight: $topBarRowHeight;
$breadcrumbsBarHeight: 5.6rem;
$modalHeaderHeight: 5.6rem;
$modalFooterHeight: 7.2rem;
$modalOutsideSpace: 10rem;
$tabsHeight: 5.6rem;

$mobileMenuWidth: 25rem;

// XD design grid in 8px units
$space-1x: 8px;
$space-2x: 16px;
$space-3x: 24px;
$space-4x: 32px;

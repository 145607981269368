@import 'variables';

$table-tr-hover-background-color: rgba(0, 0, 0, 0.05);
$table-tr-active-background-color: rgba(var(--primary-background-color), 0.1);
$table-tr-disabled-background-color: rgba(0, 0, 0, 0.1);

.column-resizing {
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: col-resize;
}

.cdk-header-cell,
digi-complex-header-cell {
  position: relative;

  &.cdk-drag-preview {
    box-sizing: border-box;
    padding: 0 15px;
    position: relative;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
    background-color: $background-color-primary;
    pointer-events: unset !important;
    cursor: move;

    * {
      cursor: move;
    }
  }

  &.cdk-drag-placeholder {
    color: transparent;
    position: relative;
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);

    * {
      opacity: 0;
    }
  }

  &.cdk-drag-placeholder::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 5px;
    right: 5px;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.1);
    border: dashed 1px rgba(0, 0, 0, 0.4);
  }

  &.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
}

.cdk-drop-list-dragging {
  digi-cell {
    cursor: move;
  }
}

digi-complex-row {
  cursor: pointer;
}

.digi-column-title {
  digi-stage-badge {
    @extend %regular-box-shadow;
    background-color: $color-light-background-color;
  }

  a {
    @extend %font-size-regular;
    @extend %regular-font;
    overflow: hidden;
  }
}

digi-complex-header-row + digi-complex-row {
  border-top: none !important;
}

digi-complex-table:not(.table-overflowed) {
  digi-complex-row {
    &:not(:first-child) {
      border-top: 1px solid $border-color;
    }

    &:hover,
    &.disabled:hover {
      background-color: $table-tr-hover-background-color;
    }

    &.disabled {
      background-color: $table-tr-disabled-background-color;
    }

    &.active,
    &.active.disabled {
      background-color: $table-tr-active-background-color;
    }
  }
}

.table-overflowed {
  digi-complex-row {
    &:not(:first-child) {
      digi-complex-cell {
        border-top: 1px solid $border-color;
      }
    }

    &.disabled {
      digi-complex-cell {
        background-color: $table-tr-disabled-background-color;
      }
    }

    &.active,
    &.active.disabled {
      digi-complex-cell {
        background-color: $table-tr-active-background-color;
      }
    }
  }
}

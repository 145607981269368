@font-face {
  font-family: 'icomoon';
  src: url('./icons/icomoon.eot?2lhhrs');
  src: url('./icons/icomoon.eot?2lhhrs') format('embedded-opentype'), url('./icons/icomoon.woff2?2lhhrs') format('woff2'),
    url('./icons/icomoon.ttf?2lhhrs') format('truetype'), url('./icons/icomoon.woff?2lhhrs') format('woff'),
    url('./icons/icomoon.svg?2lhhrs') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-lock_person:before {
  content: '\e9ac';
}
.icon-manage_search:before {
  content: '\e962';
}
.icon-plus-circle:before {
  content: '\e900';
}
.icon-upload:before {
  content: '\e9af';
}
.icon-minus-circle:before {
  content: '\e902';
}
.icon-x-circle:before {
  content: '\e903';
}
.icon-box:before {
  content: '\e904';
}
.icon-printer:before {
  content: '\e905';
}
.icon-align_right:before {
  content: '\e906';
}
.icon-add_photo:before {
  content: '\e907';
}
.icon-upload1:before {
  content: '\e9a8';
}
.icon-align_center:before {
  content: '\e909';
}
.icon-align_left:before {
  content: '\e90a';
}
.icon-refresh-cw:before {
  content: '\e90b';
}
.icon-check-circle:before {
  content: '\e90c';
}
.icon-copy:before {
  content: '\e90d';
}
.icon-forum:before {
  content: '\e90e';
}
.icon-users:before {
  content: '\e90f';
}
.icon-edit_off:before {
  content: '\e910';
}
.icon-moon:before {
  content: '\e911';
}
.icon-attachment:before {
  content: '\e912';
}
.icon-wrench2:before {
  content: '\e913';
}
.icon-horizontal_rule:before {
  content: '\e914';
}
.icon-flag:before {
  content: '\e915';
}
.icon-sun:before {
  content: '\e916';
}
.icon-file-empty:before {
  content: '\e917';
}
.icon-chat:before {
  content: '\e918';
}
.icon-list-bulleted:before {
  content: '\e919';
}
.icon-list-numbered:before {
  content: '\e91a';
}
.icon-inbox1:before {
  content: '\e91b';
}
.icon-language:before {
  content: '\e91c';
}
.icon-replay_30:before {
  content: '\e91d';
}
.icon-power_settings_new:before {
  content: '\e91e';
}
.icon-repeat:before {
  content: '\e91f';
}
.icon-hash:before {
  content: '\e920';
}
.icon-notifications:before {
  content: '\e921';
}
.icon-share:before {
  content: '\e922';
}
.icon-file-plus:before {
  content: '\e923';
}
.icon-clipboard:before {
  content: '\e924';
}
.icon-at-sign:before {
  content: '\e925';
}
.icon-square:before {
  content: '\e926';
}
.icon-crosshair:before {
  content: '\e927';
}
.icon-alert-triangle:before {
  content: '\e928';
}
.icon-task:before {
  content: '\e929';
}
.icon-folder-plus:before {
  content: '\e92a';
}
.icon-loop:before {
  content: '\e92b';
}
.icon-image:before {
  content: '\e92c';
}
.icon-check-square:before {
  content: '\e92d';
}
.icon-shield:before {
  content: '\e92e';
}
.icon-file:before {
  content: '\e92f';
}
.icon-layers:before {
  content: '\e930';
}
.icon-database:before {
  content: '\e931';
}
.icon-server:before {
  content: '\e932';
}
.icon-tag:before {
  content: '\e933';
}
.icon-pause:before {
  content: '\e934';
}
.icon-rotate-ccw:before {
  content: '\e935';
}
.icon-save:before {
  content: '\e936';
}
.icon-troubleshoot:before {
  content: '\e937';
}
.icon-cpu:before {
  content: '\e938';
}
.icon-workflow:before {
  content: '\e939';
}
.icon-edit:before {
  content: '\e93a';
}
.icon-dvr:before {
  content: '\e93b';
}
.icon-system_settings:before {
  content: '\e93c';
}
.icon-replay_5:before {
  content: '\e98c';
}
.icon-activity:before {
  content: '\e93e';
}
.icon-slash:before {
  content: '\e93f';
}
.icon-select_all:before {
  content: '\e940';
}
.icon-compare:before {
  content: '\e941';
}
.icon-tune:before {
  content: '\e942';
}
.icon-queue:before {
  content: '\e943';
}
.icon-logout:before {
  content: '\e944';
}
.icon-settings_backup_restore:before {
  content: '\e945';
}
.icon-supervised_user_circle:before {
  content: '\e946';
}
.icon-file_download_off_black:before {
  content: '\e947';
}
.icon-notifications1:before {
  content: '\e948';
}
.icon-history:before {
  content: '\e949';
}
.icon-sort:before {
  content: '\e95e';
}
.icon-image_search:before {
  content: '\e94b';
}
.icon-zoom_out_map:before {
  content: '\e94c';
}
.icon-library_add_check:before {
  content: '\e94d';
}
.icon-arrow_drop_down:before {
  content: '\e94e';
}
.icon-minus:before {
  content: '\e94f';
}
.icon-keyboard_backspace:before {
  content: '\e950';
}
.icon-arrow_circle_down:before {
  content: '\e951';
}
.icon-arrow_circle_up:before {
  content: '\e952';
}
.icon-disabled_by_default:before {
  content: '\e953';
}
.icon-circle:before {
  content: '\e954';
}
.icon-cancel-square:before {
  content: '\e955';
}
.icon-radio_button_on:before {
  content: '\e956';
}
.icon-panorama_fisheye:before {
  content: '\e957';
}
.icon-indeterminate_check_box:before {
  content: '\e958';
}
.icon-check_box:before {
  content: '\e959';
}
.icon-check_box_outline_blank:before {
  content: '\e95a';
}
.icon-clear:before {
  content: '\e95b';
}
.icon-push_pin_outline:before {
  content: '\e95c';
}
.icon-push_pin:before {
  content: '\e95d';
}
.icon-cancel:before {
  content: '\e963';
}
.icon-east:before {
  content: '\e95f';
}
.icon-west:before {
  content: '\e960';
}
.icon-auto_awesome:before {
  content: '\e961';
}
.icon-drag_indicator:before {
  content: '\e965';
}
.icon-forward_to_inbox:before {
  content: '\e967';
}
.icon-clock:before {
  content: '\e968';
}
.icon-plus:before {
  content: '\e969';
}
.icon-arrow-left:before {
  content: '\e96a';
}
.icon-arrow-down:before {
  content: '\e96b';
}
.icon-arrow-right:before {
  content: '\e96c';
}
.icon-arrow-up:before {
  content: '\e96d';
}
.icon-message-circle:before {
  content: '\e96e';
}
.icon-check:before {
  content: '\e96f';
}
.icon-x:before {
  content: '\e970';
}
.icon-settings:before {
  content: '\e971';
}
.icon-scissors:before {
  content: '\e972';
}
.icon-crop:before {
  content: '\e973';
}
.icon-trash-2:before {
  content: '\e974';
}
.icon-alert-circle:before {
  content: '\e975';
}
.icon-chevron-up:before {
  content: '\e976';
}
.icon-chevron-down:before {
  content: '\e977';
}
.icon-maximize-2:before {
  content: '\e978';
}
.icon-minimize:before {
  content: '\e979';
}
.icon-grid_view:before {
  content: '\e97a';
}
.icon-help-circle:before {
  content: '\e97b';
}
.icon-info:before {
  content: '\e97c';
}
.icon-link:before {
  content: '\e97d';
}
.icon-unlock:before {
  content: '\e97e';
}
.icon-mail:before {
  content: '\e97f';
}
.icon-menu:before {
  content: '\e980';
}
.icon-chevron-left:before {
  content: '\e981';
}
.icon-chevron-right:before {
  content: '\e982';
}
.icon-user:before {
  content: '\e983';
}
.icon-rotate-cw:before {
  content: '\e984';
}
.icon-search:before {
  content: '\e985';
}
.icon-star-filled:before {
  content: '\e986';
}
.icon-sliders:before {
  content: '\e987';
}
.icon-zoom-in:before {
  content: '\e988';
}
.icon-zoom-out:before {
  content: '\e989';
}
.icon-users1:before {
  content: '\e98b';
}
.icon-settings1:before {
  content: '\e98d';
}
.icon-send:before {
  content: '\e98e';
}
.icon-play:before {
  content: '\e98f';
}
.icon-more-vertical2:before {
  content: '\e990';
}
.icon-lock:before {
  content: '\e991';
}
.icon-inbox:before {
  content: '\e992';
}
.icon-home:before {
  content: '\e993';
}
.icon-list_view:before {
  content: '\e994';
}
.icon-filter:before {
  content: '\e995';
}
.icon-eye-off:before {
  content: '\e996';
}
.icon-edit-2:before {
  content: '\e997';
}
.icon-download-cloud:before {
  content: '\e998';
}
.icon-calendar:before {
  content: '\e999';
}
.icon-archive:before {
  content: '\e99b';
}
.icon-external-link:before {
  content: '\e99c';
}
.icon-eye:before {
  content: '\e99e';
}
.icon-folder:before {
  content: '\e99f';
}
.icon-star:before {
  content: '\e9a0';
}
.icon-downloading:before {
  content: '\e901';
}
.icon-recent_actors:before {
  content: '\e9ae';
}
.icon-admin_panel_settings:before {
  content: '\e9aa';
}
.icon-display_settings:before {
  content: '\e9a7';
}
.icon-unfold_less:before {
  content: '\e9a4';
}
.icon-unfold_more:before {
  content: '\e9a5';
}
.icon-warning:before {
  content: '\e966';
}
.icon-play_arrow:before {
  content: '\e99a';
}
.icon-volume_down:before {
  content: '\e99d';
}
.icon-volume_mute:before {
  content: '\e9a1';
}
.icon-volume_off:before {
  content: '\e9a2';
}
.icon-volume_up:before {
  content: '\e9a3';
}
.icon-create:before {
  content: '\e9a9';
}
.icon-filter_list:before {
  content: '\e94a';
}
.icon-bar_chart:before {
  content: '\e9ab';
}
.icon-wb_sunny:before {
  content: '\e9ad';
}
.icon-wifi_off:before {
  content: '\e908';
}
.icon-check_circle:before {
  content: '\e98a';
}
.icon-swap_horiz:before {
  content: '\e9b2';
}
.icon-watch_later:before {
  content: '\e964';
}
.icon-menu_open:before {
  content: '\e9a6';
}
.icon-image_not_supported:before {
  content: '\e93d';
}
.icon-more_time:before {
  content: '\e9b0';
}

@import 'variables';

.input-primary-container {
  box-shadow: 0 0 0 1px $shadow-color inset;
  transition: all 300ms ease-in-out;
  background-color: $color-light-background-color !important;
  height: 4rem;
  @extend %center-content;
  @extend %border-radius-regular;

  &.has-error {
    box-shadow: 0 0 0 1px $color-error inset;
  }

  &.focused,
  &.active,
  &:hover {
    box-shadow: 0 0 0 2px $shadow-color inset !important;
  }
}

.input-primary {
  border: none !important;

  //spacing
  padding: $space-1x !important;
  box-sizing: border-box;

  //text styling
  @extend %regular-font;
  font-size: 1.4rem !important;
  color: $color-text-dark;
  background-color: transparent;
  width: 100%;

  &.ng-dirty.ng-invalid {
    box-shadow: 0 0 0 1px $color-error inset !important;
    height: 100%;
  }

  &.ng-dirty.ng-invalid:hover {
    box-shadow: none !important;
  }

  &::placeholder {
    color: $color-text;
    opacity: 1; /* Firefox */
  }
}

.textarea-primary-container {
  @extend .input-primary-container;
  flex-direction: column;
  height: auto;
  padding: $space-1x;
}

.textarea-primary {
  @extend .input-primary;
  resize: vertical;
  max-height: 50rem;
  overflow: auto;
  display: block;
  margin: 0 !important;
  background-color: transparent !important;
  padding: 0 !important;
  outline: none !important;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

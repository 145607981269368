@import 'variables';

%transition-btn-default {
  transition: all 300ms ease-in-out;
}

button[disabled] {
  pointer-events: none;
  opacity: 0.5;

  /*color: rgba(170, 170, 170, 0.34901960784313724) !important;
  background-color: #f7f7f7 !important;*/
}

.button {
  font-size: 1.3rem;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  outline: 0;
  padding: 0.5rem 1.5rem !important;
}

.alt-button {
  @extend button;
  @extend %center-content;
  color: $color-text;

  &:hover {
    background-color: $main-btn-hover !important;
  }

  &:active &.cdk-keyboard-focused {
    background-color: $main-btn-active !important;
  }
}

.primary-text-button {
  @extend .text-button !optional;
  @extend .button;
  @extend %center-content;
  color: $color-primary;
}

.primary-button {
  @extend .primary-text-button;
  color: #fff;
}

.secondary-text-button {
  @extend .text-button !optional;
  @extend .button;
  @extend %border-radius-regular-deprecated;
}

.secondary-button {
  @extend .secondary-text-button;
  background-color: $color-secondary-background-color !important;
}

.secondary-button,
.secondary-text-button {
  @extend %center-content;
  color: $color-text;
  transition: background-color 100ms ease-in-out;

  &:hover {
    background-color: $secondary-btn-hover !important;
  }

  &:active {
    background-color: $secondary-btn-active !important;
  }

  &[disabled],
  &.disabled {
    opacity: 0.6;
  }
}

.light-button {
  @extend .text-button !optional;
  @extend .button;
  background-color: $color-primary-background-color !important;
  color: $color-text;
}

.light-alt-button {
  @extend .light-button;
  background-color: $light-alt-background-color !important;
}

.loading-button {
  cursor: progress;
  opacity: 0.25;
}

.danger-button {
  color: $color-danger !important;
}

.icon-button-filled {
  @extend %font-size-regular;
  @extend .light-alt-button;
  @extend %center-content;
  height: 4rem !important;
  width: 4rem !important;
  box-shadow: none !important;
  border: none !important;
  border-radius: 50% !important;

  i {
    color: $color-secondary-icon;
  }
}

.simple-btn {
  @extend %center-content;
  @extend %border-radius-circle;

  color: $color-text-dark;
  box-sizing: border-box;
  height: $item-large;
  padding: 0 $space-1x;
  border: 1px solid transparent;
  width: fit-content;

  &:hover {
    background-color: $background-light-color;
  }

  &:active {
    border-color: $border-color;
  }

  // keyboard navigation
  &:focus {
    @extend .with-keyboard-focus;
  }
}

//mimic the design of btn-simple
// add tab-index on the container to have a focus state
.container-simple-btn {
  @extend .simple-btn;
  border-radius: 5px !important;
}

.light-background-hover {
  &:hover {
    background-color: #00000033 !important;
  }
}

.dark-background-hover {
  &:hover {
    background-color: #ffffff33 !important;
  }
}

.with-target-size {
  &::after {
    content: '';
    min-width: $touch-area-target-size;
    height: $touch-area-target-size;
    width: 100%;
    position: absolute;
    left: 0;
    top: -8%;
    border: none;
  }
}

.with-keyboard-focus {
  outline: $outline-dashed-color dashed 2px;
  outline-offset: -2px;
}

.btn-icon-simple {
  @extend %transition-btn-default;
  @extend %center-content;
  background-color: transparent;

  * {
    color: $color-text-light-gray;
  }

  &.cdk-keyboard-focused,
  &:active,
  &:hover {
    * {
      color: $color-text-dark;
    }

    background-color: lighten($background-light-color-hover, 10%);
  }

  // keyboard navigation
  &.cdk-keyboard-focused {
    outline-color: $outline-dashed-color !important;
  }
}

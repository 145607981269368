@import 'variables';

.form-control {
  margin-bottom: 10px;

  label {
    @extend %bold-font;
    display: block;
    margin-bottom: 0.5rem;
    @extend %font-size-small;
    color: $color-text-dark;
  }

  input {
    display: block;
    padding: 0.375rem 0.75rem;
    @extend %font-size-regular;
    @extend %border-radius-regular;
    line-height: 1.5;
    color: $color-text-dark;
    border: 1px solid $border-color;
  }

  textarea {
    width: 100%;
    min-height: 10rem;
    resize: vertical;
    max-height: 50rem;
    transition: all 100ms ease-in-out;
    //spacing
    margin-top: 0.5rem;
    padding: 1rem;
    box-sizing: border-box;
    overflow: auto;
    display: block;
    background-color: $color-light-background-color;
    line-height: 1.5;

    //border styling
    border-color: $border-color;
    border-width: 1px;
    border-style: solid;
    @extend %border-radius-regular;

    //text styling
    @extend %regular-font;
    @extend %font-size-regular;
    color: $color-text-dark;

    &:focus {
      outline: none;
    }
  }
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus {
  -webkit-text-fill-color: $color-text-dark;
  -webkit-box-shadow: 0 0 0 1000px $color-light-background-color inset;
  height: calc(100% - 0.4rem);
  width: calc(100% - 0.4rem);
  @extend %border-radius-regular;
}
